import {
  MapContainer, Marker, ZoomControl, TileLayer,
} from 'react-leaflet';
import React, { Fragment, useEffect, useState } from 'react';
import L, { LatLng, LatLngBounds, LatLngTuple } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';
import usePublicAssets, { Filter, Property } from '../hooks/usePublicAssets';
import Drawer from './Drawer';
import SensorPopup from './SensorPopup';
import SensorTypeIcon from './SensorTypeIcon';
import LoadAffiliate from '../partners/LoadAffiliate';

function LeafletMap() {
  const [filters, setFilters] = useState<Filter[]>([]);
  const { assets, paginationMeta, isLoading } = usePublicAssets({ filters });
  const [currentAssetCount, setCurrentAssetCount] = useState<number>(0);
  const [totalAssetCount, setTotalAssetCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const latLong: LatLngTuple = [
    Number(process.env.REACT_APP_START_LAT),
    Number(process.env.REACT_APP_START_LONG),
  ];
  const affiliatePolygon = LoadAffiliate()?.polygon;

  useEffect(() => {
    if (!paginationMeta) {
      return;
    }

    if (filters.length === 0) {
      setTotalAssetCount(paginationMeta.total);
    }

    setCurrentAssetCount(paginationMeta.total);
  }, [isLoading, paginationMeta, filters]);

  function latLongFromProperty(property: Property | undefined): LatLngTuple {
    if (property) {
      const ll = property.value.split(',');

      if (ll[0] && !Number.isNaN(Number(ll[0])) && ll[1] && !Number.isNaN(Number(ll[1]))) {
        return [Number(ll[0]), Number(ll[1])];
      }
    }

    return [0, 0];
  }

  function RenderMarker(sensorType: string) {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <div className="relative">
          <div className="sensor-type-icon absolute top-3 left-4 text-wc-blue">
            <SensorTypeIcon sensorType={sensorType} size="2xl" />
          </div>
        </div>,
      ),
      className: '',
      iconSize: [32, 32],
      iconAnchor: [16, 0],
    });
  }

  return (
    <div className="h-[calc(100%_-_6rem)]">
      <Drawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFilters={setFilters}
        totalResultCount={totalAssetCount}
        currentResultCount={currentAssetCount}
      />
      <MapContainer
        center={latLong}
        zoom={Number(process.env.REACT_APP_DEFAULT_ZOOM_LEVEL)}
        minZoom={10}
        scrollWheelZoom
        zoomControl={false}
        maxBounds={new LatLngBounds(
          new LatLng(50.6309883, 3.0625035),
          new LatLng(53.7181849, 7.2928109),
        )}
      >
        <TileLayer
          attribution=""
          url={`${process.env.REACT_APP_WMTS_URL}/topoplus_achtergrond/wm_19/{z}/{x}/{y}.jpeg`}
        />

        <ZoomControl position="bottomright" />
        <button
          type="button"
          className="absolute text-black z-[420] top-5 left-5 bg-white p-2 rounded-lg border-2 border-black"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon icon={faSliders} size="2xl" />
        </button>

        {affiliatePolygon}

        <MarkerClusterGroup
          chunkedLoading
        >
          {!isLoading ? assets.filter((asset) => (
            asset.properties.find((property) => (property.type === 'coordinates'))
          )).map((asset) => (
            <Fragment key={`marker-${asset.id}`}>
              <Marker
                key={asset.id}
                title={asset.name}
                icon={RenderMarker((asset.properties.find((property) => (property.type === 'sensor_type'))?.value ?? '').replaceAll('_', ' '))}
                position={latLongFromProperty(asset.properties.find((property) => (property.type === 'coordinates')))}
              >
                <SensorPopup asset={asset} />
              </Marker>
            </Fragment>
          )) : ''}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default LeafletMap;
