import React from 'react';
import WecityLogo from '../images/wecity_logo_main.png';

function About() {
  return (
    <div className="flex justify-center">
      <div className="text-black max-w-2xl space-y-3">
        <h1 className="text-3xl pt-3">
          Over het Sensorregister
          {' '}
          <div className="inline capitalize">{process.env.REACT_APP_AFFILIATE_NAME}</div>
        </h1>
        <p>
          Slimme camera&lsquo;s, beacons, een zitbank of een vuilnisbak met een
          internetconnectie, laadpalen, straatverlichting uitgerust met sensoren, enz. Op openbare
          plaatsen in de stad vind je steeds meer Internet of Things (IoT)
          apparaten verschijnen. Als er op een dag in jouw buurt zo&lsquo;n apparaat wordt
          geïnstalleerd, is het niet altijd even transparant en duidelijk waarom, of is de
          informatie over het apparaat niet altijd even toegankelijk. Terecht stel je je
          dan direct vragen. Daar biedt het sensorregister een antwoord op.
        </p>
        <p>
          Het sensorregister heeft tot doel om laten zien welke sensoren er
          in de openbare ruimte zijn, van wie die zijn, waarom het er hangt en welke data er
          wordt verzameld. Dit sluit aan bij de eisen die AVG stelt.
        </p>
        <p>
          Het sensorregister is een online register dat de locatie en
          gegevens van zoveel mogelijk “Internet of Things”-apparaten in de stad op
          een kaart weergeeft.
        </p>
        <div>
          Powered by:
          {' '}
        </div>
        <img src={WecityLogo} alt="WeCity" className="h-[40px] logo" />

      </div>
    </div>
  );
}

export default About;
