import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import LoadAffiliate from '../partners/LoadAffiliate';

function Layout() {
  const affiliateLogo = LoadAffiliate()?.logo;

  return (
    <div className="map overflow-y-auto">
      <div className="header flex bg-wc-blue top-0 z-[420] h-24 py-2">
        <div className="flex flex-grow grid grid-cols-2 self-center justify-center">
          <div className="logo-container justify-self-start text-wc-gray-500 text-sm text-center mx-4">
            <Link to="/" className="header text-white">
              {affiliateLogo}
            </Link>
          </div>

          <div className="header-links justify-self-end h-full flex flex-row my-auto items-center text-lg">
            <div>
              <Link to="/" className="header mx-4 text-white">Kaart</Link>
            </div>
            <div>
              <Link to="/about" className="header mx-4 text-white">Over het register</Link>
            </div>
            <div>
              <Link to="/disclaimer" className="header mx-4 text-white">Disclaimer</Link>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Layout;
