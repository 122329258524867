import { createGlobalStyle } from 'styled-components';

const BrabantStyle = createGlobalStyle`
body,
html,
.map {
    height: 100vh;
    min-width: 100%;
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    color: black !important;
}

.btn {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.sensor-type-icon  {
  color: rgba(255, 0, 0, 0.8) !important;
}

.header {
    background-color: white !important;
    height: 100px;
    color: black !important;
}

.logo {
    width: 300px;
}

.header-links {
    justify-self: end;
}

.logo-container{
    color: red;
}

.leaflet-container {
    position: relative;
    height: 100%;
}

.marker-cluster-small div {
    background-color: rgba(173, 23, 65, 0.6) !important;
}

.marker-cluster-small {
    background-color: rgba(255, 0, 0, 0.6) !important;
}

.marker-cluster-small div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-medium div {
    background-color: rgba(173, 23, 65, 0.6) !important;
}

.marker-cluster-medium {
    background-color: rgba(255, 0, 0, 0.6) !important;
}

.marker-cluster-medium div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-large div {
    background-color: rgba(173, 23, 65, 0.6) !important;
}

.marker-cluster-large {
    background-color: rgba(255, 0, 0, 0.6) !important;
}

.marker-cluster-large div span {
    color: white;
    font-weight: bold;
}
`;
export default BrabantStyle;
