import React from 'react';
import { ReactComponent as WecityLogo } from '../images/wecity_logo.svg';
import BrabantLogo from './brabant/logo.png';
import BrabantStyle from './brabant/Brabant.styled';
import AboutBrabant from './brabant/AboutBrabant';
import About from '../components/About';
import Breda from './breda/Breda';
import Utrecht from './utrecht/Utrecht';
import { ReactComponent as UtrechtLogo } from './utrecht/utrecht_provincie_logo.svg';
import Brabant from './brabant/Brabant';
import Disclaimer from '../components/Disclaimer';
import BrabantDisclaimer from './brabant/BrabantDisclaimer';
import UtrechtStyle from './utrecht/Utrecht.styled';
import Sittard from './sittard/Sittard';
import SittardStyle from './sittard/Sittard.styled';
import SittardLogo from './sittard/sittard_logo.png';

function LoadAffiliate() {
  const affiliateComponents: Affiliate[] = [
    {
      name: 'brabant',
      about: <AboutBrabant />,
      polygon: <Brabant />,
      logo: <img src={BrabantLogo} alt="brabant" className="logo" />,
      disclaimer: <BrabantDisclaimer />,
      style: <BrabantStyle />,
    },
    {
      name: 'breda',
      about: <About />,
      logo: <WecityLogo height={80} className="logo" />,
      disclaimer: <Disclaimer />,
      polygon: <Breda />,
    },
    {
      name: 'utrecht',
      about: <About />,
      polygon: <Utrecht />,
      logo: <UtrechtLogo height={80} className="logo" />,
      disclaimer: <Disclaimer />,
      style: <UtrechtStyle />,
    },
    {
      name: 'wecity',
      about: <About />,
      logo: <WecityLogo height={80} className="logo" />,
      disclaimer: <Disclaimer />,
    },
    {
      name: 'demo',
      about: <About />,
      logo: <WecityLogo height={80} className="logo" />,
      disclaimer: <Disclaimer />,
    },
    {
      name: 'sittard',
      about: <About />,
      polygon: <Sittard />,
      logo: <img src={SittardLogo} alt="sittard" className="logo" />,
      disclaimer: <Disclaimer />,
      style: <SittardStyle />,
    },
  ];

  return affiliateComponents.find(({ name }) => (name === process.env.REACT_APP_AFFILIATE_NAME));
}

export default LoadAffiliate;

type Affiliate = {
  name: string;
  about: React.ReactNode;
  logo: React.ReactNode;
  disclaimer: React.ReactNode;
  polygon?: React.ReactNode;
  style?: React.ReactNode;
};
